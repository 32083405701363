<template>
  <v-row class="py-4 px-2 my-0" dense>
    <v-col class="same-width">
      <label for="">Venue </label>
      <v-select
          v-model="product.venueId"
          :items="venues"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Venue is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProductTypes(index)"
      >
      </v-select>
    </v-col>
    <v-col class="same-width">
      <label for="">Product Type </label>
      <v-select
          v-model="product.productType"
          :items="product.productTypes"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Product Type is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="loadTypeData(index)"
      >
      </v-select>
    </v-col>
    <v-col class="same-width" v-if="getName() !== 'POS'">
      <label for="">{{ getName() }}</label>
      <v-select
          v-model="product.event_id"
          v-if="getName() == 'Event'"
          :items="product.events"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Event is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProducts(index)"
      >
      </v-select>

      <v-select
          v-model="product.trainer_id"
          v-else-if="getName() == 'Trainer'"
          :items="product.trainers"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Trainer is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProducts(index)"
      >
      </v-select>

      <v-select
          v-model="product.membership_id"
          v-else-if="getName() == 'Membership'"
          :items="product.memberships"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Membership is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProducts(index)"
      ></v-select>

      <v-select
          v-model="product.facility_id"
          v-else-if="getName() == 'Facility'"
          :items="product.facilities"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Facility is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProducts(index)"
      ></v-select>
      <v-select
          v-model="product.workshop_id"
          v-else-if="getName() == 'Academy'"
          :items="product.academies"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Academy is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProducts(index)"
      >
      </v-select>
      <v-select
          v-model="product.academy_id"
          v-else
          :items="[]"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Choose product type']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
          @change="getProducts(index)"
      >
      </v-select>



    </v-col>
    <v-col class="same-width">
      <label for="">Product </label>
      <v-select
          v-model="product.product_id"
          :items="product.products"
          item-text="name"
          item-value="id"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Product is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
      >
      </v-select>
    </v-col>
    <v-col class="same-width">
      <label for="">Quantity </label>
      <v-text-field
          outlined
          v-model="product.quantity"
          background-color="#fff"
          required
          :readonly="!!product.restrict_quantity"
          :rules="[
                    (v) => {
                      if (v > 0) {
                        return true;
                      }else if(v === 0){
                        return 'Quantity cannot be zero'
                      }
                      return 'Enter Quantity';
                    },
                  ]"
          class="q-text-field shadow-0"
          hide-details="auto"
          dense
      >
      </v-text-field>
    </v-col>

    <v-col class="same-width" v-if="!isMain">
      <label for="">Benefit Type </label>
      <v-select
          :items="[{id:'P',name:'Percentage'} , {id:'A',name:'Amount'}]"
          item-text="name"
          item-value="id"
          v-model="product.benefit_type"
          outlined
          background-color="#fff"
          required
          :rules="[(v) => !!v || 'Benefit Type is required']"
          class="q-autocomplete shadow-0"
          hide-details="auto"
          dense
      >
      </v-select>
    </v-col>
    <v-col class="same-width" v-if="!isMain && product.benefit_type === 'P' ">
      <label for="">Percentage </label>
      <v-text-field
          outlined
          v-model="product.benefit"
          background-color="#fff"
          required
          :rules="[
                    (v) => {
                      if(v <= 0){
                        return 'Percentage cannot be zero'
                      }if(v > 100){
                        return 'Percentage cannot be greater than 100'
                      }if(!v){
                        return 'Enter Percentage';
                      }
                      return true;
                    },
                  ]"
          class="q-text-field shadow-0"
          hide-details="auto"
          dense
      >
      </v-text-field>
    </v-col>
    <v-col class="same-width" v-if="!isMain && product.benefit_type === 'A' ">
      <label for="">Amount </label>
      <v-text-field
          outlined
          v-model="product.benefit"
          background-color="#fff"
          required
          :rules="[
                    (v) => {
                      if(v <= 0){
                        return 'Amount should be greater than 0'
                      }if(!v){
                        return 'Amount  is required';
                      }
                      return true;
                    },
                  ]"
          class="q-text-field shadow-0"
          hide-details="auto"
          dense
      >
      </v-text-field>
    </v-col>

    <v-col md="1" class="d-flex justify-center align-center" v-if="productsLength > 1 && index !== 0">
      <v-btn
          color="white"
          class="small mt-4"
          depressed
          @click="deleteProduct(index)"
      >
        <v-icon color="#F44336">mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
<!--    <template v-if="index === productsLength - 1 && product.is_main">-->
<!--    -->
<!--    </template>-->

  </v-row>
</template>

<script>
export default {
  name: "ProductFields",
  props: {
    product: Object,
    venues: Array,
    index: Number,
    productsLength: Number,
    isMain:Number,
  },


  mounted() {

  },
  methods: {
    getProductTypes(index) {
      console.log(`getProductTypes`,index)
      this.$emit("get-product-types", index);
    },
    loadTypeData(index) {
      this.$emit("load-type-data", index);
    },
    getProducts(index) {
      this.$emit("get-products", index);
    },
    deleteProduct(index) {
      this.$emit("delete-product", index);
    },
    addNewPackage(index) {
      this.$emit("add-product", index);
    },
    getName() {
      let name = 'Type';
      if (this.product.productType) {
        let find = this.product.productTypes?.find(e => e.id === this.product.productType);
        if (find) {
          return find.name;
        }
      }
      return name;
    },
  }
}
</script>

<style>

.same-width{
  max-width:14.28% !important;
}
</style>
