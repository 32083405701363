<template>
  <v-dialog v-model="showModal" scrollable width="1000px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card class="card-rounded-bottom" style="min-height: 600px">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         :text="`${currentSuggestion.id!=null ? 'Update Cross Sales':'Add Cross Sales'}`" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>


          <v-row dense class="mt-4">
            <v-col cols="12">
              <div class="d-flex gap-x-2 align-center">
                <div>
                  <label for="">Package Name</label>
                  <v-text-field
                      v-model="currentSuggestion.name"
                      outlined
                      required
                      :rules="[(v) => !!v || 'Package name is required']"
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                  ></v-text-field>
                </div>
                <div style="display: block; margin-top: 15px">
                  <label class="">Benefit Type</label>
                  <v-radio-group class="mt-0 mb-0 pb-0" v-model="currentSuggestion.benefit_type" :disabled="!currentSuggestion.id?false:true"
                                 row
                  >
                    <v-radio
                        label="Product Based"
                        value="PB"
                        class="custom-radio"
                        color="red"
                    />
                    <v-radio
                        label="Value Based"
                        value="VB"
                        class="custom-radio"
                        color="red"
                    />
                  </v-radio-group>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <div class=" w-full benefit-bar py-4 px-2" >
                <p class="mb-0">{{currentSuggestion.benefit_type === 'PB' ? 'Main Product':'Value' }}</p>
              </div>
            </v-col>
          </v-row>


          <v-row  v-if="currentSuggestion.benefit_type === 'VB'" dense>
            <v-col md="3" >
              <label for="">Enter Value Price </label>
              <v-text-field
                  outlined
                  background-color="#fff"
                  required
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                  v-model="currentSuggestion.value_price"
                  :rules="[
                    (v) => {
                      if(v <= 0){
                        return 'Value Price should be greater than 0'
                      }if(!v){
                        return 'Value Price  is required';
                      }
                      return true;
                    },
                  ]"
              >
              </v-text-field>
            </v-col>
          </v-row>


          <template>
            <template v-for="(product, ind) in currentSuggestion.products" >
              <v-row v-if="product.is_main  && currentSuggestion.benefit_type === 'PB'" :key="product.key">
                <SelectProduct
                    :index="ind"
                    :product="product"
                    :venues="venues"
                    :products-length="mainProducts.length"
                    :is-main="product.is_main"
                    @get-product-types="getProductTypes"
                    @load-type-data="loadTypeData"
                    @get-products="getProducts"
                    @delete-product="deleteProduct"
                    @add-product="addNewPackage"
                />
              </v-row>
            </template>
          </template>



          <v-row dense>
            <v-col cols="12"  v-if="currentSuggestion.benefit_type === 'PB'">
              <v-btn class="teal-color" @click="addNewPackage(1)" dark elevation="0" style="font-weight: 600">
                <v-icon left dark>mdi-plus</v-icon>
                Add More Products
              </v-btn>
            </v-col>
            <v-col cols="12">
              <div class=" w-full benefit-bar py-4 px-2" >
                <p class="mb-0">Value Products</p>
              </div>
            </v-col>
          </v-row>
          <template>
            <template v-for="(product, ind) in currentSuggestion.products" >
              <v-row v-if="!product.is_main" :key="product.key">
                <SelectProduct
                               :index="ind"
                               :product="product"
                               :venues="venues"
                               :products-length="valueProducts.length"
                               :is-main="product.is_main"
                               @get-product-types="getProductTypes"
                               @load-type-data="loadTypeData"
                               @get-products="getProducts"
                               @delete-product="deleteProduct"
                               @add-product="addNewPackage"
                />
              </v-row>
            </template>
          </template>


          <v-row>
            <v-col>
              <v-btn class="teal-color" @click="addNewPackage(0)" dark elevation="0" style="font-weight: 600">
                <v-icon left dark>mdi-plus</v-icon>
                Add Value Products
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="card-footer">
          <div class="d-flex justify-end ml-4" style="width: 100%">
            <div>
              <v-btn
                  class="ma-2 "
                  text
                  @click="close"
              >Close
              </v-btn
              >

              <v-btn
                  elevation="0"
                  class="ma-2 white--text blue-color" @click="saveProduct"
              >{{ currentSuggestion.id != null ? "Update" : "Save" }}
              </v-btn
              >
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>

</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import SelectProduct from "@/components/CrossSale/SelectProduct.vue";

export default {
  name: "PackageModal",
  components: {SvgIcon ,SelectProduct},
  watch: {
    editSuggestions(val){
      console.log('editSugestionWa',val)
      if (val !== null) {
        let prod = val.cross_sale_products

        this.currentSuggestion = {
          id:val.id,
          name: val.package_name,
          benefit_type: val.benefit_type??'PB',
          value_price: val.value_price,
          products: prod,
        }
        this.currentSuggestion?.products.forEach(async (p,ind) => {
          await this.getProductTypes(ind)
          await this.loadTypeData(ind,false)
          await this.getProducts(ind)
          this.$forceUpdate()
        })
      }
    },
    // id(val) {
    //   if (val != null) {
    //     this.getPackageDetails();
    //   } else {
    //     this.currentSuggestion = {
    //       id: null,
    //       name: null,
    //       products: [{
    //         venueId: null,
    //         productType: null,
    //         product_id: null,
    //         name: null,
    //         price: null,
    //         event_id: null,
    //         trainer_id: null,
    //         membership_id: null,
    //         facility_id: null,
    //         academy_id: null,
    //       }],
    //       offerPrice: 0,
    //     };
    //   }
    // },
  },
  props: {
    showModal: {type: Boolean, default: false},
    id: {type: Number, default: null},
    editSuggestions: {type: Object, default: null},
  },

  mounted() {
    console.log('mounted',this.editSuggestions)
  },
  computed: {
    venues() {
      return this.$store.getters.userVenues;
    },


    mainProducts(){
      return this.currentSuggestion.products.filter(p=>p.is_main);
    },

    valueProducts(){
      return this.currentSuggestion.products.filter(p=>!p.is_main);
    }

  },
  data() {
    return {
      deleted_cross_sale_product_id:[],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      currentSuggestion: {
        id: null,
        name: null,
        benefit_type:"PB",
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          academy_id: null,
          is_main:1,
          benefit_type: null,
          benefit: null,
        }],
      },
      valid: false,
    }
  },
  methods: {
    async getProductTypes(index){
      console.log('getProductTypes index',index)
      this.currentSuggestion.products[index].productTypes = [];
      this.currentSuggestion.products[index].facilities = [];
      this.currentSuggestion.products[index].events = [];
      this.currentSuggestion.products[index].trainers = [];
      this.currentSuggestion.products[index].memberships = [];
      this.currentSuggestion.products[index].academies = [];
      this.currentSuggestion.products[index].products = [];
      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/offers/suggestions/get/product-types/${this.currentSuggestion.products[index].venueId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let permissions = response.data.data.permissions;
              let chio  = Object.values(permissions)
                  .filter(permission => permission.allowed === 1)
                  .map(permission => ({
                    id: permission.id,
                    name: permission.name
                  }));
                this.currentSuggestion.products[index].productTypes = chio;
              this.currentSuggestion.products[index].key = `${Date.now()}-${Math.random()*Math.random()}`;
              console.log('chio',this.currentSuggestion.products[index])
              this.$forceUpdate()

            }else{
              this.currentSuggestion.products[index].productTypes = [];
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.hideLoader()
          })
    },
    async loadTypeData(index , clear=true){
      console.log(`load types index`,index ,this.currentSuggestion.products[index])
      if(this.currentSuggestion.products[index].productType === 9){
        return this.getProducts(index);
      }
      this.currentSuggestion.products[index].facilities = [];
      this.currentSuggestion.products[index].events = [];
      this.currentSuggestion.products[index].trainers = [];
      this.currentSuggestion.products[index].memberships = [];
      this.currentSuggestion.products[index].academies = [];
      this.currentSuggestion.products[index].products = [];
      if(clear){
        this.currentSuggestion.products[index].quantity = null;
        this.currentSuggestion.products[index].benefit_type = null;
        this.currentSuggestion.products[index].benefit = null;
        this.currentSuggestion.products[index].facility_id = null;
        this.currentSuggestion.products[index].membership_id = null;
        this.currentSuggestion.products[index].event_id = null;
        this.currentSuggestion.products[index].workshop_id = null;
        this.currentSuggestion.products[index].academy_id = null;
      }

      this.showLoader("Loading product Types..");
      await this.$http.get(`venues/offers/suggestions/get/type-data/${this.currentSuggestion.products[index].venueId}/${this.currentSuggestion.products[index].productType}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.currentSuggestion.products[index].facilities = response.data.data.facilities;
              this.currentSuggestion.products[index].events = response.data.data.events;
              this.currentSuggestion.products[index].trainers = response.data.data.trainers;
              this.currentSuggestion.products[index].memberships = response.data.data.memberships;
              this.currentSuggestion.products[index].academies = response.data.data.academies;
              this.currentSuggestion.products[index].key = Math.random() *100;
              this.$forceUpdate()
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.hideLoader()
          })
    },
    async getProducts(index){
      console.log('get products')
      this.currentSuggestion.products[index].products = [];
      let filter = this.currentSuggestion.products[index].productTypes.find(i => i.id === this.currentSuggestion.products[index].productType);
      let obj = this.currentSuggestion.products[index];
      let typeId = 0;

      if(filter.name === 'Event'){
        typeId = obj.event_id
      }
      if(filter.name === 'Trainer'){
        typeId = obj.trainer_id
      }
      if(filter.name === 'Membership'){
        typeId = obj.membership_id
      }
      if(filter.name === 'Facility'){
        typeId = obj.facility_id
      }
      if(filter.name === 'Academy'){
        typeId = obj.workshop_id
      }
      this.showLoader("Loading products");
      await this.$http.get(`venues/offers/suggestions/get/products/${this.currentSuggestion.products[index].venueId}/${this.currentSuggestion.products[index].productType}/${typeId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.currentSuggestion.products[index].products = response.data.data;
              this.currentSuggestion.products[index].key = Math.random() *100;
              let quantity = this.currentSuggestion.products[index].quantity;
              this.currentSuggestion.products[index].quantity = quantity ?? 1;
              this.currentSuggestion.products[index].restrict_quantity = response.data.restrict_quantity;
              this.$forceUpdate()
            }else{
              this.currentSuggestion.products[index].products = [];
            }
          }).catch(error => {
            this.errorChecker(error);
            return false;
          }).finally(() => {
            this.hideLoader()
          })
    },

    addNewPackage(isMain) {
      this.currentSuggestion.products.push({
        productTypes:[],
        products:[],
        events:[],
        trainers:[],
        memberships:[],
        facilities:[],
        academies:[],
        venueId: null,
        productType: null,
        product_id: null,
        name: null,
        event_id: null,
        trainer_id: null,
        membership_id: null,
        facility_id: null,
        academy_id: null,
        is_main:isMain,
        benefit_type: null,
        benefit: null,
      })
    },
    getName(index) {
      let name = 'Type';
      if (this.currentSuggestion.products[index].productType) {
        let find = this.currentSuggestion.products[index].productTypes.find(e => e.id === this.currentSuggestion.products[index].productType);
        if (find) {
          return find.name;
        }
      }
      return name;
    },
    getPackageDetails() {
      console.log('fetching');
    },
    confirmActions(data) {
      console.log(data);
    },
    close() {
      this.$emit('close');
    },
    deleteProduct(i){
      if(this.currentSuggestion.products[i].cross_sale_product_id){
        this.deleted_cross_sale_product_id.push(this.currentSuggestion.products[i].cross_sale_product_id);
      }
      this.currentSuggestion.products.splice(i, 1);
    },
    saveProduct() {

      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      let payload = this.currentSuggestion;
      if(this.currentSuggestion.benefit_type === 'VB'){
        payload.products = this.currentSuggestion.products.filter(e=>!e.is_main)
      }
      let data = JSON.parse(JSON.stringify(payload));
      console.log(`current suggestion`, data)
      data.products.map((ele) => {
        delete ele.academies;
        delete ele.facilities;
        delete ele.trainers;
        delete ele.events;
        delete ele.products;
        delete ele.memberships;
        delete ele.productTypes;
        delete ele.hover;
      });

      if(this.deleted_cross_sale_product_id.length > 0){
        data.deleted_cross_sale_product_id = this.deleted_cross_sale_product_id;
      }

      this.$emit('save', data);
      this.currentSuggestion = {
        id: null,
        name: null,
        benefit_type: null,
        products: [{
          productTypes:[],
          products:[],
          events:[],
          trainers:[],
          memberships:[],
          facilities:[],
          academies:[],
          venueId: null,
          productType: null,
          product_id: null,
          name: null,
          event_id: null,
          trainer_id: null,
          membership_id: null,
          facility_id: null,
          academy_id: null,
          benefit_type: null,
          benefit: null,
        }],
      };
      this.deleted_package_product_id = [];
      this.$refs.form.resetValidation();
    }
  }
}
</script>
<style lang="scss">
.benefit-bar {
  background: rgba(79, 174, 175, 0.05);
  color: rgba(79, 174, 175, 1);
  font-weight: 600;
}

.card-rounded-bottom {
  border-radius: 10px !important;
}

.card-footer {
  background: rgba(248, 250, 251, 1);
}

.text_before_price {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}

.text_after_price {
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;

}
</style>