<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between ">
          <div class="d-flex bordered qp-tab-nav">
            <div class="d-flex p-2">
              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/offers')">
                  <SvgIcon class="text-xl text-thin-black" text="Packages">
                    <template v-slot:icon>
                      <PackagesIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>

              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/suggestions')">
                  <SvgIcon class="text-xl  text-thin-black" text="Suggestions">
                    <template v-slot:icon>
                      <SuggestionsIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>

              <template v-if="checkReadPermission($modules.crossSale.management.slug)">
                <div class="nv_item d-flex p-4 pointer " @click="gotoPage('/cross-sale')">
                  <SvgIcon class="text-xl qp-tab-nav-is-active " text="Cross sale">
                    <template v-slot:icon>
                      <CrossSaleIcon/>
                    </template>
                  </SvgIcon>
                </div>
                <v-divider
                    vertical style="background-color: rgba(220, 220, 220, 1)"
                ></v-divider>
              </template>
              <template v-if="checkWritePermission($modules.offers.management.slug)">
                <div class="nv_item d-flex p-4 pointer ">
                  <SvgIcon class="text-xl text-thin-black  " text="Configuration">
                    <template v-slot:icon>
                      <ConfigIcon/>
                    </template>
                  </SvgIcon>
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex gap-x-2">
            <v-btn
                color=" darken-1"
                class="white--text blue-color ml-1"
                text
                height="48"
                v-if="checkWritePermission($modules.crossSale.management.slug)"
                @click="addSuggestion"
            >
              <AddIcon/>
              <span class="ml-1">Add Cross Sale</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <div class="mt-4 pb-4" v-for="(activeCrossSale, i) in suggestions" :key="i">
      <h3 class="heading_package">{{ activeCrossSale.name }}</h3>
      <div class="d-flex align-center flex-wrap gap-x-8 gap-y-6">
        <v-card style="border-radius: 8px" class="shadow suggestions_package_card p-0"
                v-for="(product,ind) in activeCrossSale.products.filter(p=>p.is_main)" :key="ind+'-'+i"
        >
          <v-card-text class="p-0 relative">
            <v-menu v-if="ind===0"
                absolute
                content-class="q-menu"
                right
                top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :ripple="false"
                    absolute
                    class="text_capitalize"
                    elevation="0"
                    right
                    style="background-color: transparent; min-width: fit-content !important; height: fit-content !important; padding: 2px !important; top:5px "
                    top
                    v-bind="attrs"
                    v-on="on"
                >
                  <DotsIcon/>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-if="checkWritePermission($modules.crossSale.management.slug)"
                    @click="editSuggestionsFunc(activeCrossSale.id)"
                >
                  <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
                    <template #icon>
                      <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                    </template>
                  </SvgIcon>
                </v-list-item>
                <v-divider class="mb-2"/>
                <v-list-item
                    @click="deleteSuggestionFunc(activeCrossSale.id)"
                    v-if="checkDeletePermission($modules.crossSale.management.slug)"
                >
                  <SvgIcon text="Delete" class="red--text svg-stroke-red font-medium text-sm gap-x-2">
                    <template #icon>
                      <ActivateIcon/>
                    </template>
                  </SvgIcon>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="p-4 ">
              <div class="mt-2 pt-0 pb-1 height-text">
                <p class="property_name mb-0">Venue</p>
                <p class="property_value mt-0 mb-1 line-clamp-1">{{ product.venueName }}</p>
              </div>
              <div class="mt-1 py-0 pb-1 height-text inner_text_first_package_card">
                <p class="property_name mb-0">Type</p>
                <p class="property_value mt-0 mb-1">{{ product.productTypeName ? product.productTypeName : product.productType }}</p>
              </div>

              <div class="mt-1 py-0 height-text inner_text_first_package_card">
                <p class="property_name mb-0">Product</p>
                <p class="property_value mt-0 mb-2">{{ product.productName }}</p>
              </div>
            </div>
            <div class="d-flex justify-center align-center nth_card_footer py-2" style="width: 100%">{{ (product.price)| toCurrency }} </div>
          </v-card-text>
        </v-card>

        <v-card style="border-radius: 8px" class="shadow suggestions_package_card p-0"
                v-if="activeCrossSale.benefit_type === 'VB' "
        >
          <v-card-text class="p-0 relative">
            <v-menu
                    absolute
                    content-class="q-menu"
                    right
                    top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :ripple="false"
                    absolute
                    class="text_capitalize"
                    elevation="0"
                    right
                    style="background-color: transparent; min-width: fit-content !important; height: fit-content !important; padding: 2px !important; top:5px "
                    top
                    v-bind="attrs"
                    v-on="on"
                >
                  <DotsIcon/>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    @click="editSuggestionsFunc(activeCrossSale.id)"
                    v-if="checkWritePermission($modules.crossSale.management.slug)"

                >
                  <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
                    <template #icon>
                      <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
                    </template>
                  </SvgIcon>
                </v-list-item>
                <v-divider class="mb-2"/>
                <v-list-item
                    @click="deleteSuggestionFunc(activeCrossSale.id)"
                    v-if="checkDeletePermission($modules.crossSale.management.slug)"

                >
                  <SvgIcon text="Delete" class="red--text svg-stroke-red font-medium text-sm gap-x-2">
                    <template #icon>
                      <ActivateIcon/>
                    </template>
                  </SvgIcon>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="p-4 ">
              <div class="mt-2 pt-0 pb-1 height-text">
                <p class="property_name mb-0">Venue</p>
                <p class="property_value mt-0 mb-1 line-clamp-1">{{activeCrossSale.venueName}}</p>
              </div>
              <div class="mt-1 py-0 pb-1 height-text inner_text_first_package_card">
                <p class="property_name mb-0">Type</p>
                <p class="property_value mt-0 mb-1">Value Based</p>
              </div>
              <div class="mt-1 py-0 pb-1 height-text inner_text_first_package_card">
                <p class="property_name mb-0">Product</p>
                <p class="property_value mt-0 mb-1">N/A</p>
              </div>
            </div>
            <div class="d-flex justify-center align-center package_card_footer py-2" style="width: 100%">
              {{ activeCrossSale.valuePrice | toCurrency }}
            </div>
          </v-card-text>
        </v-card>


        <v-btn
            color=" darken-1"
            class="white--text blue-color "
            text
            height="42"
            width="42"
        >
          =
        </v-btn>
        <Suggestions
            v-for="(product,ind) in activeCrossSale.products.filter(e=>!e.is_main)"
            :key="ind"
            :product="product"
            :index="i+1"
        />
        <div class="last_card d-flex justify-center align-center"
             v-if="checkWritePermission($modules.crossSale.management.slug)">
          <v-btn
              elevation="0"
              color="white"
              class="teal--text"
              fab
              tile
              @click="editSuggestionsFunc(activeCrossSale.id)"
              v-if="checkWritePermission($modules.crossSale.management.slug)"

          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

        </div>
      </div>

    </div>

    <div class="  d-flex align-center justify-center" style="width: 250px; margin: 200px auto 0;"
         v-if="suggestions.length === 0">
      <v-col md="12">
        <v-img
            src="../../assets/images/offers_icons/no_cross_sale.png"
        ></v-img>
      </v-col>
    </div>


    <CrossSaleModal v-if="suggestionsModal"
        :showModal="suggestionsModal"
        :id="suggestionId"
        :editSuggestions="editSuggestions"
        @close="closeSuggestionsModal"
        @save="save"
    />

    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>
<script>
import ConfigIcon from "@/assets/images/trainers/cog_icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import PackagesIcon from "@/assets/images/offers_icons/packages.svg";
import SuggestionsIcon from "@/assets/images/offers_icons/suggestions.svg";
import CrossSaleIcon from "@/assets/images/offers_icons/cross_sale.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import CrossSaleModal from "@/components/CrossSale/CrossSaleModal.vue";
import Suggestions from "@/components/Suggestions/Suggestions.vue";
import EditIcon from "@/assets/images/tables/edit.svg";
import DotsIcon from "@/assets/images/misc/h-options.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  name: "Offers.vue",
  components: {
    Suggestions,
    CrossSaleModal,
    AddIcon,
    PackagesIcon,
    SuggestionsIcon,
    SvgIcon, ConfigIcon,
    CrossSaleIcon,
    EditIcon,
    DotsIcon,
    ActivateIcon
  },
  data() {
    return {
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      suggestionsModal: false,
      suggestionId: null,
      editSuggestions: {},
      suggestions: [
      ],
    }
  },
  mounted() {
    this.loadCrossSales()
  },
  computed: {},
  watch: {},
  methods: {
    confirmActions(data) {
      if (data.type == "delete") {
        this.confirmDelete(data.id)
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    confirmDelete(id){
      this.$http.get(`venues/offers/cross-sales/delete/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.showSuccess(response.data.message);
              this.loadCrossSales()
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    editSuggestionsFunc(id) {
      // this.editSuggestions = this.suggestions[ind];
      // console.log(this.editSuggestions);
      // this.editSuggestions.index = ind;
      this.suggestionsModal = true;
      this.$http.get(`venues/offers/cross-sales/get/${id}`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let responseData = response.data.data;
              this.editSuggestions = responseData;
              this.suggestionsModal = true;
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },

    deleteSuggestionFunc(id){
      this.confirmModel = {
        id: id,
        title: "Do you want to delete this package?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    gotoPage(route) {
      this.$router.push(route);
    },
    closeSuggestionsModal() {
      this.suggestionId = null;
      this.suggestionsModal = false;
      this.editSuggestions = {}
    },
    addSuggestion() {
      this.suggestionsModal = true;
    },



    loadCrossSales(){
      this.showLoader("Loading Suggestions");
      this.$http.get(`venues/offers/cross-sales`)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              let responseData = response.data.data;
              console.log('response data', responseData)
              this.suggestions = responseData.map(resPackage => {
                return {
                  id: resPackage.id,
                  name: resPackage.package_name,
                  venueName: resPackage.venue.name,
                  benefit_type: resPackage.benefit_type,
                  valuePrice: resPackage.value_price,
                  products: resPackage.cross_sale_products.map(product => ({
                    venueId: product.venue_id,
                    venueName: product.venue_name,
                    productName: product.product_name,
                    productType: product.product_type,
                    name: product.product_name,
                    price: product.final_price*product.quantity,
                    is_main:product.is_main
                  }))
                };
              });


            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
    },
    save(data) {
      this.showLoader("Saving package");
      this.$http.post(`venues/offers/cross-sales/store`,data)
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.loadCrossSales();
            }
          }).catch(error => {
        this.errorChecker(error);
        return false;
      }).finally(() => {
        this.hideLoader()
      })
      this.closeSuggestionsModal();
    },
  }
}
</script>
<style scoped lang="scss">
.heading_package {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  padding-bottom: 4px;
}


.first_package_card {
  margin-top: 5px;
  width: 220px;
  height: 180px;
  background: #4FAEAF08;
  border: 1px solid #4FAEAF;

  .v-card__title {
    color: #4FAEAF;
    padding: 10px;
    border-bottom: 1px solid #4FAEAF;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: left;
  }

  .inner_text_first_package_card {
    font-size: 14px;
    line-height: 14.52px;
    color: black;
    text-align: left;
  }

  .first_package_card_footer {
    border-top: 1px solid #4FAEAF;
    font-size: 14px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    padding-bottom: 20px;
  }
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.last_card {
  margin-top: 5px;
  width: 220px;
  height: 180px;
  background: #4FAEAF08;
  border: 1px dashed #4FAEAF;
  border-radius: 1rem;
}

.suggestions_package_card {
  width: 220px;
  min-height: 175px;
  top: 225px;
  left: 507px;
  gap: 0;
  border: 1px solid #4FAEAF;
  background: #4FAEAF08;
  border-radius: 4px 0 0 0;
  position: unset;

  .package_card_footer {
    color: rgba(79, 174, 175, 1);
    background: rgba(79, 174, 175, 0.1);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .property_name {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;

  }

  .property_value {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
  }

  .height-text {
    color: black;
    min-height: 30px;
  }

  .nth_card_footer {
    background: rgba(17, 42, 70, 0.05);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>